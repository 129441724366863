



































































import {
  Component, Prop, Vue, Ref
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DevicesAction, DevicesActionType } from '@/store/modules/devices/types';

const devices = namespace('devices');

@Component
export default class DevicesAddCsvDialog extends Vue {
  @Prop(Boolean) readonly sendFileDialogOpened!: boolean;

  @Ref() fileInput!: HTMLInputElement

  dialogLoadingUpload: boolean = false;

  selectedFile: string | null = null;

  uploading: boolean = false;

  message: string | null = null;

  errorMessage: string | null = null;

  fileOverDropzone = 0;

  successfullyAdded: string | number = '';

  fileRowCount: string | number = '';

  correctFileFormat: boolean = false;

  @devices.Action(DevicesAction.UploadCsvFile)
  UploadCsvFile!: DevicesActionType['UPLOAD_CSV_FILE'];

  get messageClasses() {
    return {
      message: this.message,
      'message--error': this.errorMessage
    };
  }

  get messageIsFileName() {
    return this.message?.includes('.');
  }

  get dialogFileCheck(): boolean {
    return this.sendFileDialogOpened;
  }

  set dialogFileCheck(value) {
    this.$emit('dialogClosed', value);
  }

  onFileSelected(event: any, eventType: string) {
    const [firstIndex] = eventType === 'dropped' ? event.dataTransfer.files : event.target.files;
    if (firstIndex === undefined || !firstIndex) return null;
    const result = this.checkFileTypeCsvFormat(firstIndex.name, firstIndex.type);
    this.fileOverDropzone = 0;
    /* eslint-disable-next-line */
    this.errorMessage && (this.errorMessage = null);
    if (result) {
      this.message = firstIndex.name;
      this.selectedFile = firstIndex;
      return this.selectedFile;
    }
    this.errorMessage = 'invalidFileFormat';
    return null;
  }

  checkFileTypeCsvFormat(fileName: string, fileType: string) {
    if (!fileName || !fileType) return false;

    const allowedFileExtention = '.csv';
    const allowedFileType = 'text/csv';
    const fileExtention = fileName.slice(-4).toLowerCase();

    if (fileExtention !== allowedFileExtention || fileType !== allowedFileType) {
      this.correctFileFormat = false;
      return false;
    }
    this.correctFileFormat = true;
    return true;
  }

  async uploadCsvFile(e: any) {
    e.preventDefault();
    if (this.selectedFile === null) {
      this.errorMessage = 'noFileChosen';
      return;
    }
    this.errorMessage = null;
    this.message = 'commissioning';
    this.dialogLoadingUpload = true;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    try {
      await this.UploadCsvFile(formData);
      const result = this.$store.getters['devices/GET_CSV_RESULT'];
      this.message = 'success';
      this.successfullyAdded = result.inserted;
      this.fileRowCount = result.rowCount;
      this.dialogLoadingUpload = false;
      this.$emit('commisioningSuccessful');
    } catch (error) {
      this.message = null;
      this.errorMessage = 'error';
      this.dialogLoadingUpload = false;
      console.error(error);
    }
  }

  closeDialog() {
    this.message = null;
    this.errorMessage = null;
    this.selectedFile = null;
    this.successfullyAdded = '';
    this.fileRowCount = '';
    this.correctFileFormat = false;
    this.fileOverDropzone = 0;
    this.fileInput.value = '';
    this.dialogFileCheck = false;
  }
}
