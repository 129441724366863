


























































































import {
  Component, Vue, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  AppActionType, AppAction,
  AppGetterType, AppState
} from '@/store/modules/app/types';
import { DeviceType } from '../DevicesCard.vue';
import { Models } from '@mtap-smartcity/api';
import { DevicesAction, DevicesActionType, DevicesState } from '@/store/modules/devices/types';
import { sort } from '@/utils/sort';
import { focusOnNewItemNameInput } from '@/utils/focus_on_input';
import { nameFilter } from '@/utils/name_filter';
import DevicesListItem from './DevicesListItem/DevicesListItem.vue';
import DevicesAddCsvDialog from './DevicesAddCsvDialog/DevicesAddCsvDialog.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { isControlCabinetTelemetry, isGateway, isLamp } from '@/utils/type_check';
import { TelemetryGetter, TelemetryGetterType } from '@/store/modules/telemetry/types';
import SearchAndAddBox, { FilterOption, FilterParam } from '@/components/base/SearchAndAddBox.vue';
import { isInAllowedDelay } from '@/utils/allowed_delay';
import CardTabs from '@/components/base/CardTabs.vue';

const app = namespace('app');
const devices = namespace('devices');
const admin = namespace('admin');
const telemetry = namespace('telemetry');

@Component({
  components: {
    CardTabs,
    SearchAndAddBox,
    DevicesListItem,
    DevicesAddCsvDialog
  }
})
export default class DevicesCardDevicesList extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus']

  @app.State
  selectedDevicesTab!: AppState['selectedDevicesTab']

  @devices.State
  devices!: DevicesState['devices']

  @devices.State
  selectedId!: DevicesState['selectedId']

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @app.Getter
  runtimeConfig!: AppGetterType['GET_RUNTIME_CONFIG'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @app.Action(AppAction.SetSelectedDevicesTab)
  setSelectedDevicesTab!: AppActionType['SET_SELECTED_DEVICES_TAB']

  @devices.Action(DevicesAction.SetSelectedId)
  SetSelectedId!: DevicesActionType['SET_SELECTED_ID'];

  @devices.Action(DevicesAction.FetchDevices)
  fetchDevices!: DevicesActionType['FETCH_DEVICES'];

  @devices.Action(DevicesAction.AddDevicePlaceholder)
  addDevicePlaceholder!: DevicesActionType['ADD_DEVICE_PLACEHOLDER'];

  @devices.Action(DevicesAction.RemoveDevicePlaceholder)
  removePlaceholderDevice!: DevicesActionType['REMOVE_DEVICE_PLACEHOLDER'];

  addOptionsVisible: boolean = false;

  sendFileDialogOpened = false;

  get tabs() {
    const cardTabs = [
      { name: this.$tc('main.lamp', 2), value: DeviceType.Lamp },
      { name: this.$tc('main.gateway', 2), value: DeviceType.Gateway },
      { name: this.$tc('main.sensor', 2), value: DeviceType.Sensor },
      { name: this.$tc('main.controlCabinet', 2), value: DeviceType.ControlCabinet },
    ];
    return cardTabs;
  }

  get isPlaceholderDeviceInStore() {
    return this.devices.some((m) => !m.id);
  }

  get deviceNamesArray() {
    const deviceNames: string[] = [];
    this.devices.forEach((d: Models.Devices.Model) => {
      deviceNames.push(d.object_id);
    });
    return deviceNames;
  }

  get permissionCheckWrite() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'devices');
    return getPermissions.write;
  }

  get permissionCheckDelete() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'devices');
    return getPermissions.delete;
  }

  filteredDevices(searchPhrase: string, filterParams: Array<FilterParam> = []): Array<Models.Devices.Device> {
    const controlMode = filterParams.find((fP: FilterParam) => fP.name === 'controlMode')?.value;
    const controllerType = filterParams.find((fP: FilterParam) => fP.name === 'controllerType')?.value;
    const activityStatus = filterParams.find((fP: FilterParam) => fP.name === 'activityStatus')?.value;
    const onlineStatus = filterParams.find((fP: FilterParam) => fP.name === 'onlineStatus')?.value;
    let sortedDevices = this.devicesOfSelectedType.sort((a, b) => sort(a.object_id, b?.object_id));
    if (controlMode && String(controlMode).length) {
      sortedDevices = sortedDevices.filter((d) => d.overwrite_enabled === (controlMode === 'manual'));
    }
    if (controllerType && String(controllerType).length) {
      sortedDevices = sortedDevices.filter((d) => d.controller_type === controllerType);
    }
    if (onlineStatus && String(onlineStatus).length) {
      sortedDevices = sortedDevices.filter((d) => this.deviceIsOnline(d.object_id, d.device_type) === (onlineStatus === 'online'));
    }
    if (this.selectedDevicesTab === 'lamp' && activityStatus && String(activityStatus).length) {
      sortedDevices = sortedDevices.filter((d) => this.lampIsOn(d) === (activityStatus === 'on'));
    }
    const filteredByPhrase = sortedDevices.filter((d) => nameFilter(d.object_id, searchPhrase));
    return filteredByPhrase;
  }

  get devicesOfSelectedType() {
    return [...this.devices].filter((d) => {
      if (
        (this.selectedDevicesTab === 'gateway'
        || this.selectedDevicesTab === 'control-cabinet')
        && isGateway(d)
      ) {
        const gatewayTelemetry = this.getLastTelemetry(d.object_id, d.device_type);
        if (!gatewayTelemetry) return d.device_type === this.selectedDevicesTab;
        const deviceType = (
          isControlCabinetTelemetry(gatewayTelemetry)
            ? 'control-cabinet'
            : d.device_type
        );
        return deviceType === this.selectedDevicesTab;
      }
      return d.device_type === this.selectedDevicesTab;
    });
  }

  get filterOptions(): Array<FilterOption> {
    const controllerTypes = [...new Set(this.devicesOfSelectedType.map((d) => d.controller_type))];
    const controllerSelectOptions = controllerTypes.map((type: string) => ({
      text: type,
      value: type
    }));
    const filterOptions = [
      {
        name: 'controlMode',
        selectOptions: [
          {
            text: '-',
            value: ''
          },
          {
            text: `${this.$t('telemetries.manualControl')}`,
            value: 'manual'
          },
          {
            text: `${this.$t('telemetries.automaticControl')}`,
            value: 'automatic'
          },
        ]
      },
      {
        name: 'controllerType',
        selectOptions: [
          {
            text: '-',
            value: ''
          },
        ].concat(controllerSelectOptions)
      },
      {
        name: 'onlineStatus',
        selectOptions: [
          {
            text: '-',
            value: ''
          },
          {
            text: `${this.$t('telemetries.online')}`,
            value: 'online'
          },
          {
            text: `${this.$t('telemetries.offline')}`,
            value: 'offline'
          },
        ]
      },
    ];
    if (this.selectedDevicesTab === 'lamp') {
      filterOptions.push({
        name: 'activityStatus',
        selectOptions: [
          {
            text: '-',
            value: ''
          },
          {
            text: `${this.$t('telemetries.on')}`,
            value: 'on'
          },
          {
            text: `${this.$t('telemetries.off')}`,
            value: 'off'
          },
        ]
      });
    }
    return filterOptions;
  }

  selectDevice(device: Models.Devices.Device) {
    if (this.userActionStatus.status === 'pending'
      || this.isPlaceholderDeviceInStore) return;
    if (this.selectedId && this.selectedId === device.id) {
      this.SetSelectedId(null);
    } else {
      this.SetSelectedId(device.id ? device.id : null);
    }
  }

  showAddDeviceOptions() {
    this.removePlaceholderDevice();
    this.addOptionsVisible = true;
  }

  addDevice() {
    // eslint-disable-next-line no-return-assign
    setTimeout(() => this.addOptionsVisible = false, 250);

    if (!this.devices.some((d) => !d.id)) {
      this.SetSelectedId(null);
      this.addDevicePlaceholder({ type: this.selectedDevicesTab });
    }
    focusOnNewItemNameInput();
  }

  openSendFileDialog() {
    this.removePlaceholderDevice();
    this.sendFileDialogOpened = true;
    // eslint-disable-next-line no-return-assign
    setTimeout(() => this.addOptionsVisible = false, 250);
  }

  defaultSelect() {
    const availableDevices = this.filteredDevices('', []);
    if (this.selectedDevicesTab
    && availableDevices.findIndex((d: Models.Devices.Device) => d.id === this.selectedId) !== -1
    ) return;
    if (availableDevices.length) {
      this.SetSelectedId(availableDevices[0].id ? availableDevices[0].id : null);
    } else {
      this.SetSelectedId(null);
    }
  }

  deviceIsOnline(objectId: string, deviceType: Models.Constants.DeviceType) {
    const deviceTelemetry = this.getLastTelemetry(objectId, deviceType);
    return isInAllowedDelay(deviceTelemetry, this.runtimeConfig);
  }

  lampIsOn(device: Models.Devices.Device): boolean | null {
    if (!isLamp(device!)) return null;
    let result: boolean | null = false;
    const deviceTelemetry = this.getLastTelemetry(device.object_id, device.device_type);
    if (!this.deviceIsOnline(device.object_id, device.device_type)) {
      result = false;
      // @ts-ignore
    } else if (deviceTelemetry && (deviceTelemetry.telemetry.set_duty > 0 || deviceTelemetry.telemetry.setDuty > 0)) {
      result = true;
    }
    return result;
  }

  mounted() {
    this.defaultSelect();
  }

  destroyed() {
    this.SetSelectedId(null);
    this.setSelectedDevicesTab('lamp');
    this.removePlaceholderDevice();
  }

  @Watch('selectedDevicesTab')
  onSelectedDevicesTabChange() {
    this.removePlaceholderDevice();
    this.defaultSelect();
  }
}
