export default {
  lamp: {
    device: [
      {
        name: 'producer', dataType: 'string', value: null
      },
      {
        name: 'model', dataType: 'string', value: null
      },
      {
        name: 'serviceLife', dataType: 'number', value: null
      },
      {
        name: 'luminousEfficiency', dataType: 'number', value: null
      },
      {
        name: 'maxPower', dataType: 'number', value: null
      },
      {
        name: 'latitude', dataType: 'number', value: null
      },
      {
        name: 'longitude', dataType: 'number', value: null
      },
      {
        name: 'installationDate', dataType: 'date', value: null
      },
    ],
    powerSupply: [
      {
        name: 'producer', dataType: 'string', value: null
      },
      {
        name: 'model', dataType: 'string', value: null
      },
      {
        name: 'type', dataType: 'string', value: null
      },
      // {
      //   name: 'parts', dataType: 'array', value: null
      // },
      {
        name: 'maxPower', dataType: 'number', value: null
      },
    ],
    controller: {
      iothub: [
        {
          name: 'bridgeSn', dataType: 'string', value: null
        },
        {
          name: 'netId', dataType: 'number', value: null
        },
        {
          name: 'subnetId', dataType: 'number', value: null
        },
        {
          name: 'gatewaySn', dataType: 'string', value: null
        },
        {
          name: 'daliShortAddress', dataType: 'number', value: null
        },
      ],
      iqrf: [
        {
          name: 'serialNumber', dataType: 'string', value: null
        },
        {
          name: 'gateway', dataType: 'string', value: null
        },
      ],
      ve: [
        {
          name: 'bridgeSn', dataType: 'string', value: null
        },
        {
          name: 'netId', dataType: 'number', value: null
        },
        {
          name: 'subnetId', dataType: 'number', value: null
        },
        {
          name: 'gatewaySn', dataType: 'string', value: null
        },
        {
          name: 'daliShortAddress', dataType: 'number', value: null
        },
      ],
    }
  },
  gateway: {
    device: [
      {
        name: 'latitude', dataType: 'number', value: null
      },
      {
        name: 'longitude', dataType: 'number', value: null
      },
    ],
    powerSupply: [],
    controller: [
      {
        name: 'gatewaySn', dataType: 'string', value: null
      },
      {
        name: 'netId', dataType: 'string', value: null
      },
    ]
  },
  sensor: {
    device: [
      {
        name: 'producer', dataType: 'string', value: null
      },
      {
        name: 'latitude', dataType: 'number', value: null
      },
      {
        name: 'longitude', dataType: 'number', value: null
      },
      {
        name: 'installationDate', dataType: 'date', value: null
      },
    ],
    controller: [],
    powerSupply: [],
  },
  bridge: {
    device: [
      {
        name: 'luminaires', dataType: 'number', value: null
      }
    ],
    powerSupply: [],
    controller: [
      {
        name: 'bridgeSn', dataType: 'string', value: null
      },
      {
        name: 'gatewaySn', dataType: 'string', value: null
      },
      {
        name: 'netId', dataType: 'string', value: null
      },
      {
        name: 'subnetId', dataType: 'string', value: null
      },
      {
        name: 'luminaires', dataType: 'string', value: null
      },
    ]
  },
  'control-cabinet': {
    device: [
      {
        name: 'producer', dataType: 'string', value: null
      },
      {
        name: 'latitude', dataType: 'number', value: null
      },
      {
        name: 'longitude', dataType: 'number', value: null
      },
      {
        name: 'installationDate', dataType: 'date', value: null
      },
    ],
    controller: [],
    powerSupply: [],
  },
};
