var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-devices-list-card"},[_c('div',{staticClass:"c-devices-list-card__tabs"},[_c('CardTabs',{attrs:{"tabs":_vm.tabs,"selectedTab":_vm.selectedDevicesTab},on:{"update:selected-tab":_vm.setSelectedDevicesTab}})],1),_c('div',{staticClass:"c-devices-list-card__list"},[_c('SearchAndAddBox',{attrs:{"searchPlaceholder":_vm._f("capitalize")(_vm.$t('actions.search', { element: _vm.$tc('main.device') })),"btnRemove":_vm.permissionCheckWrite && _vm.permissionCheckDelete,"btnDisabled":_vm.addOptionsVisible,"filter":true,"filterOptions":_vm.filterOptions},on:{"add-item":_vm.showAddDeviceOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var searchPhrase = ref.searchPhrase;
var computedFilterParams = ref.computedFilterParams;
return _vm._l((_vm.filteredDevices(searchPhrase, computedFilterParams)),function(d){return _c('DevicesListItem',{key:d.id,attrs:{"device":d,"forbiddenNames":_vm.deviceNamesArray},nativeOn:{"click":function($event){return _vm.selectDevice(d)}}})})}}])}),_c('DevicesAddCsvDialog',{attrs:{"sendFileDialogOpened":_vm.sendFileDialogOpened},on:{"dialogClosed":function($event){_vm.sendFileDialogOpened = false},"commisioningSuccessful":_vm.fetchDevices}}),_c('transition',[(_vm.addOptionsVisible)?_c('div',{staticClass:"c-devices-list-card__add-options-buttons"},[_c('div',{staticClass:"c-base-round-button c-base-round-button--empty"}),_c('v-tooltip',{attrs:{"top":"","z-index":"200","open-on-focus":false,"open-on-click":false,"open-delay":300,"close-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"c-base-round-button",attrs:{"color":"#34ebc9","fab":"","disabled":false,"small":""},on:{"click":_vm.addDevice}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-pencil-plus-outline")])],1)]}}],null,false,39140439)},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.createDeviceManually')))])]),(_vm.selectedDevicesTab === 'lamp')?_c('v-tooltip',{attrs:{"top":"","z-index":"200","open-on-focus":false,"open-on-click":false,"open-delay":300,"close-delay":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"c-base-round-button",attrs:{"color":"#34ebc9","fab":"","disabled":false,"small":""},on:{"click":_vm.openSendFileDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18px"}},[_vm._v("mdi-file-upload-outline")])],1)]}}],null,false,686074026)},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.importCsv')))])]):_vm._e(),_c('v-btn',{staticClass:"c-base-round-button c-base-round-button--close",attrs:{"color":"rgba(0, 0, 0, 0.38)","fab":"","disabled":false,"x-small":""},on:{"click":function($event){_vm.addOptionsVisible = false}}},[_c('v-icon',{attrs:{"color":"#34ebc9"}},[_vm._v("mdi-close")])],1)],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }