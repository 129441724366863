























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppState } from '@/store/modules/app/types';
import DevicesCardDevicesList from './DevicesCardDevicesList/DevicesCardDevicesList.vue';
import DevicesCardDeviceTabbedForm from './DevicesCardDeviceTabbedForm/DevicesCardDeviceTabbedForm.vue';

const app = namespace('app');

export enum DeviceType {
  Lamp = 'lamp',
  Gateway = 'gateway',
  Sensor = 'sensor',
  ControlCabinet = 'control-cabinet'
}

@Component({
  components: {
    DevicesCardDevicesList,
    DevicesCardDeviceTabbedForm,
  },
})
/**
 * @group Devices Card
 * Devices tab content
 */
export default class DevicesCard extends Vue {
  @app.State
  userActionStatus!: AppState['userActionStatus'];
}
