var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'c-editable-list-item': true,
    'c-editable-list-item--edit': _vm.editState,
    'c-editable-list-item--selected': _vm.isSelected,
  }},[_c('BaseTooltip',{attrs:{"position":"top","content":!_vm.editState ? _vm.device.object_id : _vm.$t('tooltips.nameUniqueAndNotEmpty'),"disabled":_vm.editState && !_vm.showNameTooltip},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"c-editable-list-item__name"},on),[_c('BaseTextField',{attrs:{"placeholder":_vm.$t('main.name'),"value":_vm.device.object_id,"disabled":!_vm.editState,"maxlength":35},on:{"change":function($event){_vm.deviceData.object_id = $event}}})],1)]}}])}),(_vm.device.device_type === 'lamp')?[_c('BaseTooltip',{attrs:{"position":"top","content":_vm.$t('tooltips.controllerType', { controllerType: _vm.device.controller_type })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var on = ref.on;
return [_c('div',_vm._g({staticClass:"c-editable-list-item__controller"},on),[_c('VBaseSelect',{attrs:{"placeholder":_vm.$t('deviceParameters.type'),"disabled":!_vm.isPlaceholderItem,"appendIcon":_vm.isPlaceholderItem && _vm.editState ? '$dropdown' : '',"items":_vm.selectItems,"attach":'.c-vbase-select'},model:{value:(_vm.deviceData.controller_type),callback:function ($$v) {_vm.$set(_vm.deviceData, "controller_type", $$v)},expression:"deviceData.controller_type"}})],1)]}}],null,false,1804236348)}),_c('BaseTooltip',{attrs:{"position":"top","content":_vm._f("capitalize")(_vm.$t('tooltips.deviceNotBonded')),"extra":_vm._f("capitalize")(_vm.$t('actions.bondDeviceAgain')),"open-delay":300},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return [_c('v-icon',_vm._g({class:{
              'c-editable-list-item__bond-icon': true,
              'c-editable-list-item__bond-icon--show': !_vm.deviceIsBondedWithGateway && !_vm.isPlaceholderItem
          },attrs:{"disabled":_vm.recommissionForbidden,"icon":"","color":hover ? 'rgba(237, 134, 17, 0.57)' : 'grey',"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.recommissionDeviceOnce(_vm.device)}}},on),[_vm._v(" "+_vm._s(hover || _vm.recommissionForbidden ? 'mdi-connection' : 'mdi-power-plug-off')+" ")])]}}],null,true)})]}}],null,false,45252468)}),_c('BaseTooltip',{attrs:{"position":"top","content":_vm.$t(("deviceErrors[" + _vm.deviceError + "]"))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var on = ref.on;
return [(!_vm.isPlaceholderItem)?_c('v-icon',_vm._g({class:{
          'c-editable-list-item__error-icon': true,
          'c-editable-list-item__error-icon--show': !!_vm.deviceError
        },attrs:{"size":"1.6rem","color":"red"}},on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,false,3096249008)}),_c('BaseTooltip',{attrs:{"position":"top","content":_vm.$t(("tooltips.deviceStatus[" + (_vm.deviceIsToggledOn ? 0 : 1) + "]"))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var on = ref.on;
return [(!_vm.isPlaceholderItem)?_c('v-icon',_vm._g({staticClass:"c-editable-list-item__device-status",attrs:{"size":"1.6rem","color":_vm.deviceIsToggledOn ? 'orange' : 'grey'}},on),[_vm._v(" "+_vm._s(_vm.deviceIsToggledOn ? "mdi-lightbulb-on-outline" : "mdi-lightbulb-outline")+" ")]):_vm._e()]}}],null,false,467791739)})]:_vm._e(),_c('BaseTooltip',{attrs:{"position":"top","content":_vm.$t(("tooltips.connectionStatus[" + (_vm.deviceIsOnline ? 0 : 1) + "]")),"extra":_vm.deviceIsOnline ? null : _vm.$t('tooltips.connectionStatus[2]', { date: _vm.deviceLastConnectionDate })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var on = ref.on;
return [(!_vm.isPlaceholderItem)?_c('span',_vm._g({staticClass:"c-editable-list-item__connection-status",class:{
        'c-editable-list-item__connection-status--is-online': _vm.deviceIsOnline,
        'c-editable-list-item__connection-status--is-disabled': _vm.isPlaceholderItem,
      }},on)):_vm._e()]}}])}),_c('BaseTooltip',{attrs:{"position":"top","content":_vm.$t('actions.showMarkerOnMap')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-icon',_vm._g({class:{
          'c-editable-list-item__error-icon': true,
          'c-editable-list-item__error-icon--show': !!_vm.deviceIsOnTheMap && !_vm.isPlaceholderItem
        },attrs:{"size":"1.6rem","color":hover ? 'rgba(237, 134, 17, 0.57)' : 'grey'},on:{"click":function($event){$event.stopPropagation();return _vm.showMarkerOnMap(_vm.device.id)}}},on),[_vm._v(" mdi-map-marker-radius-outline ")])]}}],null,true)})]}}])}),(_vm.editState)?[_c('div',{staticClass:"c-editable-list-item__action--hidden"}),_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.accept.apply(null, arguments)}}},[_vm._v(" mdi-check ")]),_c('v-icon',{staticClass:"c-editable-list-item__action",attrs:{"size":"1.6rem"},on:{"click":function($event){$event.stopPropagation();return _vm.discard.apply(null, arguments)}}},[_vm._v(" mdi-close ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }