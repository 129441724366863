










































































import {
  Component, Vue, Watch, Ref
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Models } from '@mtap-smartcity/api';
import {
  DevicesAction, DevicesActionType, DevicesGetter, DevicesGetterType, DevicesState
} from '@/store/modules/devices/types';
import { AppState } from '@/store/modules/app/types';
import {
  isControlCabinetTelemetry,
  isGateway, isIotLamp, isSensor
} from '@/utils/type_check';
import DevicePropertiesList from './DevicePropertiesList/DevicePropertiesList.vue';
import {
  UserGetter, UserGetterType
} from '@/store/modules/admin/types';
import { TelemetryGetter, TelemetryGetterType } from '@/store/modules/telemetry/types';
import DevicesAddCsvDialog
  from '@/components/TheMainCard/DevicesCard/DevicesCardDevicesList/DevicesAddCsvDialog/DevicesAddCsvDialog.vue';
import { loadConfig } from '@/api/devices';

export enum DevicePart {
  EDevice = 'device',
  PowerSupply = 'powerSupply',
  Controller = 'controller'
}

declare type DeviceParametersTypes =
  Models.Devices.LampIotParameters
  | Models.Devices.LampIqrfParameters
  | Models.Devices.LampVeParameters
  | Models.Devices.GatewayControlCabinetParameters;

const admin = namespace('admin');
const devices = namespace('devices');
const app = namespace('app');
const telemetry = namespace('telemetry');

@Component({
  components: {
    DevicesAddCsvDialog,
    DevicePropertiesList,
  }
})
export default class DevicesCardDeviceTabbedForm extends Vue {
  @Ref() devicePropsForm!: any;

  @Ref() fileInput!: HTMLInputElement;

  @app.State
  selectedDevicesTab!: AppState['selectedDevicesTab'];

  @devices.State
  selectedId!: DevicesState['selectedId'];

  @devices.Getter(DevicesGetter.GetDevice)
  getDevice!: DevicesGetterType['GET_DEVICE'];

  @devices.Getter(DevicesGetter.GetDeviceById)
  getDeviceById!: DevicesGetterType['GET_DEVICE_BY_ID'];

  @admin.Getter(UserGetter.GetPermissions)
  GetPermissions!: UserGetterType['GET_PERMISSIONS'];

  @telemetry.Getter(TelemetryGetter.GetLastTelemetry)
  getLastTelemetry!: TelemetryGetterType['GET_LAST_TELEMETRY'];

  @devices.Action(DevicesAction.UpdateDevice)
  updateDevice!: DevicesActionType['UPDATE_DEVICE'];

  editState = false;

  elementKey = 1;

  selectedTab: DevicePart = DevicePart.EDevice;

  deviceData: Models.Devices.Device | null = null;

  loadStatus: '' | 'success' | 'error' = '';

  message: string | null = null;

  errorMessage: string = '';

  get selectedDevice() {
    return this.selectedId ? this.getDeviceById(this.selectedId, this.selectedDevicesTab) : null;
  }

  get tabs() {
    const cardTabs = [
      {
        name: this.$tc('main.device'),
        value: DevicePart.EDevice
      },
      {
        name: this.$tc('main.powerSupply'),
        value: DevicePart.PowerSupply
      },
      {
        name: this.$tc('main.controller'),
        value: DevicePart.Controller
      },
    ];
    if (!this.deviceData) {
      return [];
    }
    if (isSensor(this.deviceData)) {
      return [cardTabs[0]];
    }
    if (isIotLamp(this.deviceData)) {
      return [cardTabs[0], cardTabs[1]];
    }
    if (isGateway(this.deviceData)) {
      const gatewayTelemetry = this.getLastTelemetry(this.deviceData.object_id, this.deviceData.device_type);
      if (gatewayTelemetry && isControlCabinetTelemetry(gatewayTelemetry)) return [cardTabs[0]];
      return [cardTabs[0], cardTabs[2]];
    }
    return cardTabs;
  }

  @Watch('selectedDevice', {
    deep: true,
    immediate: true
  })
  onSelectedDeviceChange(newDevice: Models.Devices.Device | null) {
    this.deviceData = newDevice ? JSON.parse(JSON.stringify(newDevice)) : null;
    this.loadStatus = '';
  }

  @Watch('selectedDevicesTab')
  onSelectedDevicesTabChange() {
    this.selectedTab = DevicePart.EDevice;
    this.loadStatus = '';
  }

  get deviceParameters() {
    if (this.deviceData) {
      return (
        (this.deviceData).parameters as DeviceParametersTypes
      )[this.selectedTab];
    }
    return null;
  }

  get controllerType() {
    if (this.deviceData) {
      return this.deviceData.controller_type;
    }
    return null;
  }

  get permissionCheck() {
    const getPermissions = this.GetPermissions.find((item) => item.type === 'devices');
    return getPermissions.write;
  }

  updateDeviceProp(newPropData: { name: string, value: string | number | Date }) {
    const {
      name,
      value
    } = newPropData;
    let newVal = value;
    // TODO rethink and refactor logic for updating devices' props
    // temporary fix
    if ((name === 'longitude' || name === 'latitude') && typeof value === 'string') {
      newVal = parseFloat(value);
    }
    ((this.deviceData as Models.Devices.Device).parameters[this.selectedTab] as any)[name] = newVal;
  }

  resetChanges() {
    this.deviceData = JSON.parse(JSON.stringify(this.selectedDevice));
    this.elementKey += 1;
  }

  selectTab(tabName: DevicePart) {
    if (this.devicePropsForm.isFormValid) {
      this.selectedTab = tabName;
    }
  }

  edit() {
    this.editState = true;
  }

  cancel() {
    this.resetChanges();
    this.editState = false;
  }

  save() {
    this.updateDevice(this.deviceData as Models.Devices.Device)
      .catch(() => {
        this.resetChanges();
      })
      .finally(() => {
        this.editState = false;
      });
  }

  async onFileSelected(event: any, eventType: string) {
    const [file]: [File] = eventType === 'dropped' ? event.dataTransfer.files : event.target.files;
    if (!file) return;
    if (file.type !== 'application/json') {
      this.loadStatus = 'error';
      this.message = `Expected file type [application/json], but got [${file.type}]`;
      return;
    }
    try {
      const content = JSON.parse(await file.text());
      this.loadStatus = 'success';
      this.message = `Loaded file: ${file.name}`;
      if (this.selectedDevice) {
        await loadConfig((this.selectedDevice as Models.Devices.Model).uuid!, content);
      }
    } catch (error) {
      this.loadStatus = 'error';
      this.message = error;
    }
  }
}
